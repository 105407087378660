import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircleLoader } from "react-spinners";
import {
  useGetDestinationQuery,
  useGetLinkByShortlinkQuery,
} from "../../services/api/linkApi";

function RedirectPage() {
  const [statusFail, setStatusFail] = useState(false);
  const { shortedlink } = useParams();
  const { data, isError } = useGetDestinationQuery(shortedlink);
  const { data: dataLink, isError: isErrorLink } =
    useGetLinkByShortlinkQuery(shortedlink);

  useEffect(() => {
    if (data && dataLink) {
      window.location.href = `https://${dataLink.data.destination}`;
    }
    if (isError || isErrorLink) {
      setStatusFail(true);
    }
  }, [data, isError, dataLink, isErrorLink]);

  return (
    <div className="container h-full flex flex-col items-center mx-auto mt-32 ">
      {!statusFail ? (
        <div className="text-center">
          <CircleLoader color="#ce36d6" className="mx-auto" />
          <h1 className="text-lg font-medium">Sabar ya, lagi otw ke tujuan</h1>
        </div>
      ) : (
        <div className="font-bold text-center">
          Sepertinya alamat yang kamu cari tidak ada diingatanku 😥
        </div>
      )}
    </div>
  );
}

export default RedirectPage;
