import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo-text.png";

function Header() {
  const navigate = useNavigate();

  const onHandleLogout = () => {
    navigate("/login");
    sessionStorage.removeItem(process.env.REACT_APP_TOKEN_ACCESS);
  };

  return (
    <>
      <header className="header fixed w-full z-50 top-0 bg-blue-800 shadow-md flex items-center justify-between py-2">
        <div className="container px-10 mx-auto flex justify-between">
          <h1 className="w-3/12">
            <Link to="/" className="flex items-center font-bold text-lg">
              <img src={logo} alt="..." className="w-40 h-12 my-2 mr-3 " />
              {/* <span className="text-2xl text-white"></span> */}
            </Link>
          </h1>
          <div className="flex items-center">
            {/* <nav className="nav font-semibold text-lg text-white mr-6">
            <ul className="flex items-ends ">
              <li className="p-4 border-b-2 border-blue-900 border-opacity-0 hover:border-opacity-100 hover:text-gray-200 duration-200 cursor-pointer">
                <Link to="home">Home</Link>
              </li>
              <li className="p-4 border-b-2 border-blue-900 border-opacity-0 hover:border-opacity-100 hover:text-gray-200 duration-200 cursor-pointer active">
                <Link to="units">Apartment</Link>
              </li>
              <li className="p-4 border-b-2 border-blue-900 border-opacity-0 hover:border-opacity-100 hover:text-gray-200 duration-200 cursor-pointer">
                <Link to="transactions">Transactions</Link>
              </li>
            </ul>
          </nav> */}

            {window.sessionStorage.getItem(
              process.env.REACT_APP_TOKEN_ACCESS
            ) && (
              <button
                onClick={onHandleLogout}
                className="bg-white font-semibold text-black  py-1 px-6 rounded border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
              >
                Logout
              </button>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
