import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardPage from "../pages/dashboard/DashboardPage";
import ErrorPage from "../pages/error/ErrorPage";
import LoginPage from "../pages/login/LoginPage";
import RedirectPage from "../pages/redirect/RedirectPage";
import ProtectRoute from "./ProtectRoute";

export default function index() {
  return (
    <Routes>
      <Route path="*" element={<ErrorPage />} />
      <Route path="to/:shortedlink" element={<RedirectPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route element={<ProtectRoute />}>
        <Route index element={<DashboardPage />} />
      </Route>
    </Routes>
  );
}
