import React, { useState } from "react";
import CardLink from "../../../components/CardLink";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDeleteLinkMutation } from "../../../services/api/linkApi";
import toast, { Toaster } from "react-hot-toast";

function Home(props) {
  const { data } = props.data;
  const { setPage, setSelected } = props;
  const [search, setSearch] = useState("");

  let total = 0;

  if (data.length !== 0) {
    data.map((item) => (total += Number(item.clickCount)));
  }
  const [deleteLink] = useDeleteLinkMutation();
  const successNotify = () =>
    toast("Yey, berhasil hapus!", {
      icon: "👏",
    });

  const doDetele = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="py-4 px-10 bg-gray-800 text-white text-base text-center rounded-xl">
            <h1 className="font-bold text-4xl">Beneran mau dihapus?</h1>
            <p className="text-2xl">Yang hilang tak bisa kembali 😥</p>
            <div className="flex items-start justify-around my-4">
              <button
                className="px-4 py-2 bg-blue-500 hover:bg-blue-700  rounded-xl"
                onClick={onClose}
              >
                Batal
              </button>
              <button
                className="px-4 py-2 bg-red-500 hover:bg-red-700 rounded-xl"
                onClick={() => {
                  deleteLink({ id }).then(() => successNotify());
                  onClose();
                }}
              >
                Gas!
              </button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className="container mx-auto px-10">
      <Toaster />

      <div className="grid grid-cols-1 gap-6 mb-6 lg:grid-cols-2">
        <div className="grid grid-cols-2 gap-4">
          <div className="w-full px-4 py-4 bg-white rounded-lg shadow">
            <div className="text-sm font-medium text-gray-500 truncate">
              Total Links
            </div>
            <div className="mt-1 text-3xl font-semibold text-gray-900">
              {data.length}
            </div>
          </div>

          <div className="w-full px-4 py-4 bg-white rounded-lg shadow">
            <div className="text-sm font-medium text-gray-500 truncate">
              Total Clicks
            </div>
            <div className="mt-1 text-3xl font-semibold text-gray-900">
              {total}
            </div>
          </div>
        </div>

        <div className="pt-2 mb-10 relative mx-auto text-gray-600 w-full">
          <div className="font-bold mb-2">Cari Link:</div>
          <input
            className="border-2 w-full border-gray-300 bg-white h-10 px-5 py-2 rounded-lg text-sm focus:outline-none text-center"
            type="search"
            name="search"
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            placeholder="Search"
          />
          <button
            type="submit"
            className="absolute right-0 top-0 mt-5 mr-4"
          ></button>
        </div>
      </div>

      {data.length !== 0 ? (
        <>
          {data
            .filter(
              (link) =>
                link.title
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase()) ||
                link.shortlink
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
            )
            .map((item, index) => (
              <CardLink
                link={item}
                key={index}
                setPage={setPage}
                setSelected={setSelected}
                doDetele={doDetele}
              />
            ))}
        </>
      ) : (
        <div>Masih Kosong Nih. Yuk buat baru!</div>
      )}
    </div>
  );
}

export default Home;
