import React from "react";
import { Link } from "react-router-dom";

function CardLink(props) {
  const { id, title, clickCount, destination, shortlink } = props.link;
  const { setPage, setSelected, doDetele } = props;

  return (
    <div className="rounded-xl border px-5 py-2 shadow-md w-full bg-white mb-4 hover:bg-gray-100">
      <div className="flex w-full items-center justify-between border-b pb-1">
        <div className="flex items-center space-x-3">
          <div className="text-2xl font-bold text-slate-700">{title}</div>
        </div>
        <div className="flex items-center space-x-3">
          <button
            className="rounded-lg border bg-yellow-300 hover:bg-yellow-400 px-3 py-1 text-md font-bold"
            onClick={() => {
              setPage("edit");
              setSelected(props.link);
            }}
          >
            Edit
          </button>
          <button
            className="rounded-lg border bg-red-500 hover:bg-red-600 px-3 py-1 text-md text-white font-bold"
            onClick={() => doDetele(id)}
          >
            Hapus
          </button>
        </div>
      </div>

      <div className="my-2">
        <div className="mb-2">
          <Link
            to={`/to/${shortlink}`}
            target="_blank"
            className="text-xl font-bold text-blue-700"
          >
            {shortlink}
          </Link>
        </div>
        <div className="text-sm text-black ">
          <Link
            to={`/to/${shortlink}`}
            target="_blank"
            className="flex items-center space-x-1 text-md rounded-md cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
              />
            </svg>

            <span className="">{destination}</span>
          </Link>
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between text-slate-500">
          <div className="flex space-x-1">
            <div className="flex cursor-pointer items-center transition hover:text-slate-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59"
                />
              </svg>

              <span className="font-bold ml-1 text-normal">
                {clickCount} <span className="font-normal">Click</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardLink;
