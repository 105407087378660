import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const navigate = useNavigate();
  const errorNotify = () => toast.error("Username / Password Salah");
  const successNotify = () =>
    toast("Yey, berhasil!", {
      icon: "👏",
    });
  const nothingNotify = () =>
    toast("Yah, fungsi belum ada", {
      icon: "😥",
    });

  const credential = {
    username: "ijinmasukdong",
    password: "123",
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem(process.env.REACT_APP_TOKEN_ACCESS)) {
      navigate("/");
    }
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (username === credential.username && password === credential.password) {
      successNotify();
      sessionStorage.setItem(
        process.env.REACT_APP_TOKEN_ACCESS,
        credential.username
      );
      setTimeout(() => {
        navigate("/");
      }, 800);
    } else {
      errorNotify();
    }
  };

  return (
    <>
      <Toaster />
      <div className="h-screen w-full bg-blue-500 flex justify-center items-center">
        <div className="absolute w-60 h-60 rounded-xl bg-purple-300 top-5 -left-16 z-0 transform rotate-45 hidden md:block"></div>
        <div className="absolute w-48 h-48 rounded-xl bg-purple-300 bottom-10 right-10 transform rotate-12 hidden md:block"></div>
        <div className="py-12 px-12 bg-white rounded-2xl shadow-xl z-20">
          <div>
            <h1 className="text-3xl font-bold text-center mb-4 cursor-pointer">
              Sign In
            </h1>
            <p className="w-80 text-center text-sm mb-8 font-semibold text-gray-700 tracking-wide cursor-pointer">
              Sign In to enjoy all the services
            </p>
          </div>
          <form className="space-y-4" onSubmit={onSubmit}>
            <input
              type="text"
              value={username}
              onChange={({ target }) => setUsername(target.value)}
              placeholder="Username"
              className="block text-sm py-3 px-4 rounded-lg w-full border outline-none"
            />
            <input
              type="password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              placeholder="Password"
              className="block text-sm py-3 px-4 rounded-lg w-full border outline-none"
            />
            <div className="text-center mt-6">
              <button
                type="submit"
                className="py-3 w-64 text-xl text-white bg-blue-400 hover:bg-blue-600 rounded-2xl"
              >
                Sign In
              </button>
              <p className="mt-4 text-sm">
                Don't Have An Account?{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => nothingNotify()}
                >
                  {" "}
                  Sign Up
                </span>
              </p>
            </div>
          </form>
        </div>
        <div className="w-40 h-40 absolute bg-purple-300 rounded-full top-0 right-12 hidden md:block"></div>
        <div className="w-20 h-40 absolute bg-purple-300 rounded-full bottom-40 left-10 transform rotate-45 hidden md:block"></div>
      </div>
    </>
  );
}
