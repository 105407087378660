function Footer() {
  return (
    <>
      <div className=" px-36 bg-white bottom-0 w-full ">
        <div className=" border-gray-300 flex flex-col items-center">
          <div className="sm:w-2/3 text-center py-6">
            <p className="text-sm text-black font-bold mb-2">
              &copy; 2023 by{" "}
              <a
                href="https://www.baguspurnama.com"
                target="_blank"
                rel="noreferrer"
              >
                Bagus Purnama
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
