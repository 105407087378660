import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const shorterApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "https://shortlinkapi.gliderlabs.my.id/",
  }),
  reducerPath: "shortlinkApi",
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  tagTypes: ["MappingLinks"],
  endpoints: () => ({}),
});
