import React from "react";

function TinyNote() {
  return (
    <div className="container mx-auto mt-12 px-10">
      <div className="flex items-center gap-4 my-2">
        <div className="font-bold text-2xl">Tambah</div>
        <button className="py-2 px-4 bg-blue-700 rounded-lg text-white text-2xl">+</button>
      </div>

      <div className="rounded-xl border px-5 py-2 shadow-md w-full bg-white my-4 hover:bg-gray-100">
        <div className="flex w-full items-center justify-between border-b pb-1">
          <div className="flex items-center space-x-3">
            <div className="text-2xl font-bold text-slate-700">{"title"}</div>
          </div>
        </div>
        <div className="my-2">
          <textarea className="p-2 w-full" />
        </div>
      </div>
    </div>
  );
}

export default TinyNote;
