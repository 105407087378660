import React from "react";
import { PacmanLoader } from "react-spinners";

function Spinner(props) {
  const { color } = props;
  return (
    <div className="container h-full flex justify-center items-center">
      <PacmanLoader color={color} />
    </div>
  );
}

export default Spinner;
