import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import MainLayout from "./layouts/Main";
import MainRoutes from "./routes";
import MainStore from "./services/store";

export default function App() {
  return (
    <BrowserRouter>
      <Provider store={MainStore}>
        <MainLayout>
          <MainRoutes />
        </MainLayout>
      </Provider>
    </BrowserRouter>
  );
}
