import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import HomeIcon from "../../components/icons/Home";
import LinksIcon from "../../components/icons/Link";
import LogoutIcon from "../../components/icons/Logout";
import Home from "./contents/Home";
import Setting from "./contents/TinyNote";
import { useLazyGetLinksQuery } from "../../services/api/linkApi";
import Spinner from "../../components/Spinner";
import toast, { Toaster } from "react-hot-toast";
import Create from "./contents/Create";

export default function DashboardPage() {
  const navigate = useNavigate();

  const [page, setPage] = useState("home");
  const [selected, setSelected] = useState();
  const [getData, { data, isLoading }] = useLazyGetLinksQuery();
  const errorNotify = () => toast.error("No Data");

  useEffect(() => {
    getData().then((res) => {
      if (!Boolean(res.data)) {
        errorNotify();
      }
    });
  }, [getData, page]);

  const onHandleLogout = () => {
    navigate("login");
    sessionStorage.removeItem(process.env.REACT_APP_TOKEN_ACCESS);
  };

  return (
    <>
      <Toaster />
      <div className="container mx-auto flex w-screen">
        <div className="flex flex-col w-80 p-3 duration-300 bg-gray-50 shadow text-gray-700 sticky min-h-screen">
          <div className="space-y-3 mt-28">
            <div>
              <h2 className="text-4xl font-extrabold text-center">
                URL Shorter
              </h2>
            </div>
            <div className="text-center">
              <button
                className="w-full bg-blue-800 hover:bg-blue-700 px-10 py-2 rounded-lg text-white font-black text-lg"
                onClick={() => setPage("create")}
              >
                Create Link
              </button>
            </div>
            <div className="flex-1 text-md">
              <ul className="pt-2 pb-4 space-y-1">
                <li className="rounded-sm">
                  <Link
                    onClick={() => setPage("home")}
                    className="flex items-center p-2 space-x-3 rounded-md cursor-pointer hover:bg-gray-100"
                  >
                    <HomeIcon />
                    <span className="">Home</span>
                  </Link>
                </li>
                {/* <li className="rounded-sm">
                  <Link
                    onClick={() => setPage("links")}
                    className="flex items-center p-2 space-x-3 rounded-md cursor-pointer hover:bg-gray-100"
                  >
                    <LinksIcon />
                    <span className="">Links</span>
                  </Link>
                </li> */}
                <li className="rounded-sm">
                  <Link
                    onClick={() => setPage("setting")}
                    className="flex items-center p-2 space-x-3 rounded-md cursor-pointer hover:bg-gray-100"
                  >
                    <LinksIcon />
                    <span className="">Tiny Note</span>
                  </Link>
                </li>
                <li className="rounded-sm">
                  <button
                    onClick={onHandleLogout}
                    className="flex items-center p-2 space-x-3 w-full rounded-md cursor-pointer hover:bg-gray-100"
                  >
                    <LogoutIcon />
                    <span className="">Logout</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-28 w-full">
          <div className="flex-auto h-full pr-10">
            {isLoading && <Spinner color={"#330092"} />}
            {data && (
              <>
                {page === "home" && (
                  <Home
                    data={data}
                    setPage={setPage}
                    setSelected={setSelected}
                  />
                )}
                {/* {page === "links" && (
                  <Links
                    listLink={data}
                    setPage={setPage}
                    setSelected={setSelected}
                  />
                )} */}
                {page === "setting" && <Setting />}
              </>
            )}
            {page === "create" && (
              <Create setPage={setPage} dataSelected={undefined} />
            )}
            {page === "edit" && (
              <Create setPage={setPage} dataSelected={selected} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
