import { configureStore } from "@reduxjs/toolkit";
import { shorterApi } from "./api";

const MainStore = configureStore({
  reducer: {
    [shorterApi.reducerPath]: shorterApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(shorterApi.middleware),
});

export default MainStore;
