import React, { useState } from "react";
import {
  useCreateNewLinkMutation,
  useUpdateLinkMutation,
} from "../../../services/api/linkApi";
import toast, { Toaster } from "react-hot-toast";
function Create(props) {
  const { dataSelected } = props;
  const [title, setTitle] = useState(dataSelected?.title || "");
  const [destination, setDestination] = useState(
    dataSelected?.destination ?? ""
  );
  const [shortLink, setShortLink] = useState(dataSelected?.shortlink ?? "");

  const [createLink, { isError }] = useCreateNewLinkMutation();
  const [updateLink] = useUpdateLinkMutation();
  const submit = (e) => {
    e.preventDefault();
    const payload = {
      title: title,
      destination: destination.replace(/(^\w+:|^)\/\//, ""),
      shortlink: Boolean(shortLink)
        ? shortLink
        : (Math.random() + 1).toString(36).substring(7),
      userId: 2,
    };

    if (dataSelected) {
      payload["id"] = dataSelected.id;
      updateLink(payload).then((res) => {
        if (isError) {
          toast.error("Failed!");
        } else {
          toast.success("Successfully Added!");
          props.setPage("home");
        }
      });
    } else {
      createLink(payload).then((res) => {
        if (isError) {
          toast.error("Failed!");
        }
        if (Boolean(res.error)) {
          toast.error("Short Link Duplicated!");
        } else {
          toast.success("Successfully Added!");
          props.setPage("home");
        }
      });
    }
  };
  return (
    <div className="container mx-auto px-10">
      <Toaster />
      <h1 className="font-black text-4xl mb-10">
        {dataSelected ? "Edit Link" : "Create New Link"}
      </h1>
      <div>
        <form onSubmit={submit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-xl font-bold mb-2"
              htmlFor="title"
            >
              Title
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="title"
              type="text"
              value={title}
              placeholder="Ex: Link Diskusi"
              onChange={({ target }) => setTitle(target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-xl font-bold mb-2"
              htmlFor="destination"
            >
              Destination
            </label>
            <div className="flex items-center">
              <label className="text-md font-medium">https://</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="destination"
                type="text"
                value={destination}
                onChange={({ target }) => setDestination(target.value)}
                placeholder="www.my_long_url.com"
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-xl font-bold mb-2"
              htmlFor="destination"
            >
              Short Link
            </label>
            <div className="flex items-center">
              <label className="text-md font-medium">
                lmp.baguspurnamma.com/to/
              </label>
              <input
                onChange={({ target }) => setShortLink(target.value)}
                className="shadow appearance-none border rounded py-2 px-3 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="shortLink"
                type="text"
                value={shortLink}
                disabled={dataSelected ? true : false}
                placeholder="Custom back-half (optional)"
              />
            </div>
          </div>

          {!dataSelected ? (
            <button
              type="submit"
              className="bg-blue-700 rounded-md font-bold text-white text-lg hover:bg-blue-800 px-8 py-2"
            >
              Create
            </button>
          ) : (
            <button
              type="submit"
              className="bg-yellow-400 rounded-md font-bold text-white text-lg hover:bg-yellow-500 px-8 py-2"
            >
              Edit
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default Create;
